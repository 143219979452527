<template>
  <div class="research-practice h-100">
    <!-- <input type="file" accept="image/*" capture="camera" class="camera" /> -->
    <Point />
    <Earth class="h-100" />
  </div>
</template>
<script>
import Earth from "./researchPractice/components/Earth.vue";
import Point from "./researchPractice/components/Point.vue";
export default {
  components: { Earth, Point },
  data() {
    return {
      map: null,
    };
  },
  methods: {
    initMap() {},
  },
  mounted() {
    // this.initMap()
  },
};
</script>

<style lang="less" scoped>
// @import "../../style/color.less";
.research-practice {
  position: relative;
  .map_view {
    width: 100%;
    height: 100%;
    .tdt-control-copyright {
      display: none;
    }
  }
}
</style>
