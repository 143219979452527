export default class DrawTool {
    constructor(cesium) {
        const viewer = this.viewer = cesium.viewer
        // 广告牌的可渲染集合
        this.billboards = this.viewer.scene.primitives.add(new Cesium.BillboardCollection({
            scene: this.viewer.scene
        }))

        this.rotateBool = false // 是否旋转
        this.GPXArray = []

        this.modal = {
            show: false,
            style: {},
            data: {},
            title: '',
            type: ''
        }
        // 事件，左键按下弹出信息框
        this.leftDownAction()

        this.geojson = {
            "type": 'FeatureCollection',
            "features": []
        }

        return this
    }

    // 获取坐标
    setPosition(lng, lat, alt = 0) {
        return Cesium.Cartesian3.fromDegrees(lng, lat, alt)
    }

    /** 
     * 添加单个图标点
     * entities方式
     * entities方式，用法简单，属性方便
     */
    addPoint(option) {

        const {
            id,
            name,
            lng,
            lat,
            width,
            height,
            ele = 500,
            image
        } = option
        this.viewer.entities.add({
            id,
            name,
            position: new Cesium.Cartesian3.fromDegrees(lng, lat, ele),
            point: {
                color: Cesium.Color.RED, // 点的颜色
                pixelSize: 0, // 点的大小
                outlineColor: Cesium.Color.YELLOW, // 外环圈的颜色
                outlineWidth: 1, // 外环圈的宽度
            },
            billboard: {
                image: `/static/images/model/${image}`,
                width,
                height,
            },
            data: option
        })
    }

    // 鼠标事件 左键单击
    leftDownAction() {
        const viewer = this.viewer
        viewer.screenSpaceEventHandler.setInputAction((movement) => {
            var picked = viewer.scene.pick(movement.position)
            if (Cesium.defined(picked) && picked.id.id) {
                this.popupPosition(picked)
            } else {
                return
            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK)


    }
    // 设置弹框位置
    popupPosition(e) {
        let data = {}
        if(e.id.data) {
            data = e.id.data
        } else if(e.id.properties) {
            data = {
                name: e.id.properties.name._value,
                id: e.id.properties.id._value,
                type: e.id.properties.type._value,
                showImage: e.id.properties.showImage._value,
                lng: e.id.properties.lng._value,
                lat: e.id.properties.lat._value,
                ele: e.id.properties.ele._value,
            }
        }
        //经纬度转为世界坐标
        let gisPosition = Cesium.Cartesian3.fromDegrees(
            data.lng,
            data.lat,
            (data.ele || 500)
        );
        Object.assign(this.modal, {
            show: true
        }) // 弹出信息框
        const viewer = this.viewer
        //实时更新位置
        viewer.scene.postRender.addEventListener(() => {
            if (this.modal.show) {
                //转化为屏幕坐标
                var windowPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(viewer.scene, gisPosition);
                Object.assign(this.modal, {
                    data,
                    style: {
                        left: (windowPosition.x - 130).toFixed(0) + 'px',
                        top: (windowPosition.y - 155).toFixed(0) + 'px'
                    }
                })

                //解决滚动不隐藏问题
                const camerPosition = viewer.camera.position;
                let height = viewer.scene.globe.ellipsoid.cartesianToCartographic(camerPosition).height;
                height += viewer.scene.globe.ellipsoid.maximumRadius;
                if ((!(Cesium.Cartesian3.distance(camerPosition, gisPosition) > height)) && viewer.camera.positionCartographic.height < 50000000) {
                    Object.assign(this.modal, {
                        show: true
                    })
                } else {
                    Object.assign(this.modal, {
                        show: false
                    })
                }

            }

        })

    }

    // 相机绕点环绕飞行
    rotateCamera(options) {
        const viewer = this.viewer
        const position = this.setPosition(options.lng, options.lat, options.ele)
        this.rotateBool = true
        // 相机看点的角度，如果大于0则是从地底往上看，所以要为负值，这里取-30度
        let pitch = Cesium.Math.toRadians(-30)
        // 给定飞行一周所需时间，比如30s, 那么每秒转动度数
        var angle = 360 / 30;
        // 给定相机距离点多少距离飞行
        var distance = options.ele || 500;  // options.alt
        var startTime = Cesium.JulianDate.fromDate(new Date());

        var stopTime = Cesium.JulianDate.addSeconds(startTime, 30, new Cesium.JulianDate());

        viewer.clock.startTime = startTime.clone();  // 开始时间
        viewer.clock.stopTime = stopTime.clone();     // 结速时间
        viewer.clock.currentTime = startTime.clone(); // 当前时间
        viewer.clock.clockRange = Cesium.ClockRange.CLAMPED; // 行为方式
        viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK; // 时钟设置为当前系统时间; 忽略所有其他设置。
        // 相机的当前heading
        var initialHeading = viewer.camera.heading;
        var rotateExection = function TimeExecution() {
            // 当前已经过去的时间，单位s
            var delTime = Cesium.JulianDate.secondsDifference(viewer.clock.currentTime, viewer.clock.startTime);
            // 根据过去的时间，计算偏航角的变化
            var heading = Cesium.Math.toRadians(delTime * angle) + initialHeading;

            viewer.camera.lookAt(position, new Cesium.HeadingPitchRange(heading, pitch, distance));

            if (Cesium.JulianDate.compare(viewer.clock.currentTime, viewer.clock.stopTime) >= 0) {
                viewer.clock.onTick.removeEventListener(rotateExection);
            }
        };
        viewer.clock.onTick.addEventListener(rotateExection);
        this.rotateExection = rotateExection
    }

    // 停止环绕
    stopRotate() {
        this.rotateBool = false
        this.viewer.clock.onTick.removeEventListener(this.rotateExection);
    }

    // 鸟瞰图, 俯视
    birdEyeView(options, angle= 45) {
        if(this.rotateBool) {
            this.stopRotate()
        }
        const viewer = this.viewer
        const position = this.setPosition(options.lng, options.lat, options.ele)
        // 相机看点的角度，如果大于0则是从地底往上看，所以要为负值，这里取-30度
        let heading = Cesium.Math.toRadians(50)
        let pitch = Cesium.Math.toRadians(-angle)
        viewer.camera.lookAt(position, new Cesium.HeadingPitchRange(heading, pitch, 100));
    }

    /**移除billboards */
    clearBillboard() {
        this.billboards.removeAll()
    }

    // 移除gpx数据文件
    removeGpxFileData() {
        for (let i = 0; i < this.GPXArray.length; i++) {
            // console.log(this.kmlArray[i])
            this.viewer.dataSources.remove(this.GPXArray[i]);
        }
        // 将存储文件数组中的所有数据删除
        this.GPXArray = [];
    }

    // 加载gpx文件
    loadGPX(url) {
        const viewer = this.viewer
        const that = this
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, false);
        xhr.onload = function() {
            if (xhr.status == 200) {
                // removeKmlFileData();
                // removeCzmlFileData();
                that.removeGpxFileData()
                // console.log(xhr.response)
                let dataSource = Cesium.GpxDataSource.load(
                    url,
                    {
                        clampToGround: true
                    }
                );
                that.GPXArray.push(dataSource)
                viewer.dataSources.add(dataSource).then(function(dsource){
                    viewer.flyTo(dsource, {
                        //  飞行时间8秒
                        duration: 8.0,
                        // 调整飞行完成后的摄像机视角
                        offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    });
                });
                // if (this.response[0] == "<") {
                //     var dataSourcePromise = Cesium.KmlDataSource.load(url);
                //     dataSourcePromise.then(function(dataSource) {
                //         kmlArray.push(dataSource);
                //         viewer.dataSources.add(dataSource);
                //         viewer.flyTo(dataSource, {
                //             // 飞行时间8秒
                //             duration: 8.0,
                //             // 调整飞行完成后的摄像机视角
                //             offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                //         });
                //     })
                // }
            }
        };
        xhr.send();
    }

    // 加载gpx航迹文件
    gpxLayer(gpxUrl) {
        // 加载GPX文件
        const dataSource = new Cesium.GpxDataSource();
        const pinBuilder = new Cesium.PinBuilder();
        dataSource.load(gpxUrl, {
            clampToGround: true,
            trackColor: Cesium.Color.YELLOW,
            waypointImage: pinBuilder.fromMakiIconId(
            "pitch",
            Cesium.Color.BLUE,
            48
            ),
        }).then(() => {
            this.viewer.dataSources.add(dataSource);
            this.viewer.flyTo(dataSource, {
                //  飞行时间8秒
                duration: 8.0,
                // 调整飞行完成后的摄像机视角
                offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
            });
        });
    }

    /** 
     * 添加单个图标点
     * primitive方式
     * 底层调用，性能更好
     */
    // addPrimitive(position, option) {
    //     console.log("position, option:", position, option)
    //     const { lng, lat, ele = 500, width, height, image } = option
    //     this.billboards.add({
    //         // id: 'billboard',
    //         position: new Cesium.Cartesian3.fromDegrees(lng, lat, ele),
    //         image:  `/static/images/model/${image}`,
    //         width,
    //         height,
    //         verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
    //         data: {}
    //     })
    // }

    /**
     * cesium点聚合
     */
    positionMapLayer(data = [], option) {
        const { viewer, geojson } = this
        if(geojson.features.length > 0) { geojson.features = [] }
        if(data.length) {
            data.forEach(item => {
                geojson.features.push({
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [parseFloat(item.lng), parseFloat(item.lat)]
                    },
                    "properties": { ...item }
                })
            })
            const options = {
                camera: viewer.scene.camera,
                canvas: viewer.scene.canvas
            }
            // 加载geojson
            const dataSourcePromise = Cesium.GeoJsonDataSource.load(geojson, options)
            const img = `/static/images/model/${option.image}`
            
            dataSourcePromise.then((dataSource) => {
                viewer.dataSources.add(dataSource)
                dataSource.entities.values.forEach(entity => {

                    entity.billboard = {
                        image: img,
                        width: 30,
                        height: 30
                    }

                    entity.label = {
                        show: false,
                        text: '标签',
                        font: 'bold 15px Microsoft YaHei',
                        verticalOrigin: Cesium.VerticalOrigin.CENTER,
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    }

                    // 将点拉伸一定高度，防止被地形压盖
                    entity.position._value.z += 50.0;


                })
                // 设置聚合参数
                dataSource.clustering.enabled = true;
                dataSource.clustering.pixelRange = 60;
                dataSource.clustering.minimumClusterSize = 2;
                const _this = this

                // 添加监听函数
                dataSource.clustering.clusterEvent.addEventListener(
                    function(clusteredEntities, cluster) {
                        // 关闭自带的显示聚合数量的标签
                        cluster.label.show = false;
                        cluster.billboard.show = true;
                        cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;

                        // 根据聚合数量的多少设置不同层级的图片以及大小
                        if (clusteredEntities.length >= 20) {
                            cluster.billboard.image = _this.combineIconAndLabel('/static/images/model/yuan.svg', clusteredEntities.length, 64);
                            cluster.billboard.width = 72;
                            cluster.billboard.height = 72;
                        } else if (clusteredEntities.length >= 12) {
                            cluster.billboard.image = _this.combineIconAndLabel('/static/images/model/yuan.svg', clusteredEntities.length, 64);
                            cluster.billboard.width = 56;
                            cluster.billboard.height = 56;
                        } else if (clusteredEntities.length >= 8) {
                            cluster.billboard.image = _this.combineIconAndLabel('/static/images/model/yuan.svg', clusteredEntities.length, 64);
                            cluster.billboard.width = 48;
                            cluster.billboard.height = 48;
                        } else {
                            cluster.billboard.image = _this.combineIconAndLabel('/static/images/model/yuan.svg', clusteredEntities.length, 64);
                            cluster.billboard.width = 40;
                            cluster.billboard.height = 40;
                        }
                    }
                )
            })
        }
    }

    /**
     * @description: 将图片和文字合成新图标使用（参考Cesium源码）
     * @param {*} url：图片地址
     * @param {*} label：文字
     * @param {*} size：画布大小
     * @return {*} 返回canvas
     */
    combineIconAndLabel(url, label, size) {
        // 创建画布对象
        let canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        let ctx = canvas.getContext("2d");

        let promise = new Cesium.Resource.fetchImage(url).then(image => {
            // 异常判断
            try {
                ctx.drawImage(image, 0, 0);
            } catch (e) {
                console.log(e);
            }

            // 渲染字体
            // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
            ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
            ctx.font = 'bold 20px Microsoft YaHei';
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(label, size / 2, size / 2);

            return canvas;
        });
        return promise;
    }
}