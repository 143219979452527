<template>
  <div class="point">
    <Icon custom="iconfont icon-tianjiaweizhi" class="icon" @click="getPoint()" />
    <Icon custom="iconfont icon-fangxiangluxianziliao" class="icon m-t-10" />
    <icon custom="iconfont icon-iconhuizong_huaban1fuben22" class="icon m-t-10" />

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 去添加定位点页面
    getPoint() {
        this.$router.push("/system/researchPractice/positionPoint")
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../style/color.less";
.point {
  position: absolute;
  left: 10px;
  bottom: 100px;
  color: @text-color;
  z-index: 1;

  .icon {
    display: block;
    font-size: 34px;
    &:hover {
      color: @hover-color;
    }
  }
}
</style>
