<template>
    <div ref="cesium" class="cesium-wrap">
        <div class="tool-wrap">
            <button class="tool-button" id="setNavigation" @click="setNavigation">罗盘</button>
        </div>
        <InfoWindow :modal="tool.modal" v-if="tool" />
    </div>
</template>

<script>
import { CesiumMap } from '@/libs'
import DrawTool from '@/libs/mapByCesium/DrawTool.js'
import InfoWindow from '@/components/libs/InfoWindow'
import { getPointAll } from '@/libs/api/point'
export default {
    components: { InfoWindow },
    data () {
      return {
        tool: null
      }
    },
    async mounted() {
        // 加载cesium
        const cesium = await new CesiumMap(this.$refs.cesium, {
            homeButton: false,
            sceneModePicker: false,
            isCredit: true,
            isAnimation: true,
            isTimeline: true
        })
        this.cesium = cesium
        this.tool = new DrawTool(cesium)
        this.getData()

        // this.drawPolyline([{}])

        // new Cesium.InfoBox(this.$refs.infoBox)
    },
    destroyed() {
        // 从地图上移除对应id的实体
        this.cesium.viewer.entities.removeById('point');
    },
    methods: {
        
        getData() {
            getPointAll().then(res => {
                console.log(res)
                if(res.code == "HA0200") {
                    if (res.data && res.data.length) {
                        res.data.map(item => {
                            this.tool.addPoint({
                                ...item,
                                name: item.pointName,
                                id: item.researchPointId,
                                lng: item.pointCoord.lon,
                                lat: item.pointCoord.lat,
                                ele: item.pointCoord.ele,
                                width: 20,
                                height: 20,
                                image: 'leida.svg'
                            })
                            this.cesium.pointCamera(item.pointCoord.lon, item.pointCoord.lat, 100000)
                        })
                    }
                }
            })
        },
        // 添加图标
        addIcon() {
            let option = {
                id: '0',
                name: '测试地点',
                lng: 113.619310,
                lat: 34.747870,
                // alt: 100,
                width: 20,
                height: 20,
                image: 'leida.svg'
            }
            this.tool.addPoint(option)
            this.cesium.pointCamera(113.619310,34.747870)
        },
        
        // 
        drawPolyline(positions, _config) {
            let viewer = this.cesium.viewer
            if(positions.length < 1) return
            let config = _config ? _config : {}
            viewer.entities.add({
                name: '线',
                polyline: {
                    positions: positions,
                    width: config.width? config.width : 5.0,
                    material: new Cesium.PolylineGlowMaterialProperty({
                        color: config.color ? new Cesium.color.fromCssColorString(config.color) : Cesium.Color.GOLD
                    }),
                    depthFailMaterial: new Cesium.PolylineGlowMaterialProperty({
                        color: config.color ? new Cesium.color.fromCssColorString(config.color) : Cesium.Color.GOLD
                    }),
                    clampToGround: true
                }
            })
        },

        setNavigation() {
            this.cesium.setNavigation()
        }
    }
}
</script>

<style lang="less" scoped>
  .cesium-wrap {
    position: relative;
    overflow: hidden;
    .tool-wrap {
      z-index: 10;
      position: absolute;
      right: 20px;
      top: 20px;
      .tool-button {
        padding: 2px 8px;
        background: #fff;
        color: #333;
        outline: none;
        border: none;
        border-radius: 5px;
        &:hover {
          background: #5cadff;
          color: #fff;
        }
      }
    }
    .info-box {
          position: absolute;
          z-index: 10;
          width: 160px;
          height: 100px;
          color: #fff;
          background: rgba(20, 63, 91, .5);
          border-radius: 10px;
          display: none;
      }
      .info-box:after {
          content: '';
          position: absolute;
          border-width: 10px;
          border-style: solid;
          border-color: rgba(20, 63, 91, .5) transparent transparent transparent;
          top: 100px;
          left: 70px;
      }
    
  }
  
</style>